import {AvailableMediaFormats, GenericMediaType} from '../models/Generic.model';

export const MediaSource = (mediaData: GenericMediaType, format: AvailableMediaFormats = 'small', userOriginal: boolean = false): string | undefined => {
    const imageFormat = mediaData?.data?.attributes?.formats?.[format];

    return !userOriginal && imageFormat?.url ? imageFormat?.url : mediaData?.data?.attributes?.url;
}

export const MediaUrl = (mediaData: GenericMediaType, format: AvailableMediaFormats = 'small', userOriginal: boolean = false): string | undefined => {
    const imageSource = MediaSource(mediaData, format, userOriginal);

    return imageSource ? process.env.REACT_APP_BACKEND_URL! + MediaSource(mediaData, format, userOriginal) : undefined;
}

export const BackgroundImage = (mediaData: GenericMediaType,  isIntersecting: boolean, format: AvailableMediaFormats = 'small', userOriginal: boolean = false): string | undefined => {
    const imageUrl = MediaUrl(mediaData, format, userOriginal);

    return imageUrl && isIntersecting ? `url(${imageUrl})` : undefined;
}
