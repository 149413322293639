import React from 'react';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import {useTranslation} from 'react-i18next';
import {FundraisingListResponseDataItem, FundraisingListResponseDataItemStatus} from '../Fundraising/Fundraising.model';
import FundraisingItem from '../../components/FundraisingItem/FundraisingItem';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';

import './CompletedFundraising.css';
import {NavLinks} from '../../router';
import {NavLink} from 'react-router-dom';


function CompletedFundraising() {
    const {t} = useTranslation();

    const {
        data: fundraising,
        loading,
        error
    } = useFetch<FundraisingListResponseDataItem[]>('/fundraisings/?populate=*', NavLinks.reports);

    if (loading) {
        return (<Loader/>);
    }

    if (error || !fundraising?.length) {
        return (<></>);
    }

    const completedFundraising = fundraising
        .filter(fund => fund.attributes.status === FundraisingListResponseDataItemStatus.Completed)
        .sort((a, b) => b.attributes.order - a.attributes.order)

    const renderSlides = () =>
        completedFundraising
            .map((fund) => (
                <FundraisingItem key={fund.id} item={fund}/>
            ));

    return completedFundraising?.length ? (
        <div className="completed-fundraising">
            <div className="section__anchor" id={NavLinks.reports}></div>
            <h2 className="completed-fundraising__title">
                <div className="completed-fundraising__title_text">{t('completedFundraisingSection.title')}</div>
                <div className="completed-fundraising__title_icon">
                    <div className="complete__button complete__button_big"></div>
                </div>
            </h2>
            <SlickSlider slides={renderSlides}/>
            <NavLink to={`/${NavLinks.reportsPage}`}
                     className="completed-fundraising__show-all-link">
                {t('completedFundraisingSection.showAllLink')}
            </NavLink>
        </div>
    ) : (<></>);
}

export default CompletedFundraising;
