import React from 'react';
import {ResponseDataItem} from '../../common/models/Generic.model';
import useGetLocalizedField from '../../hooks/useGetLocalizedField';
import Markdown from 'react-markdown';

function LocalizedText(props: {
    item: ResponseDataItem<any>,
    field: string,
    html?: boolean,
    markdown?: boolean
}): React.JSX.Element {
    const localizedData = useGetLocalizedField(props.item, props.field);

    if (props.html) {
        return <span dangerouslySetInnerHTML={{__html: localizedData.replaceAll('\n', '<br>')}}/>
    }

    if (props.markdown) {
        return <Markdown skipHtml={true}>{localizedData}</Markdown>;
    }

    return <span>{localizedData}</span>;
}

export default LocalizedText;
