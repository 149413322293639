import React from 'react';
import useFetch from '../../hooks/useFetch';
import Loader from '../../components/Loader/Loader';
import {ReportsPageResponseDataItem} from './ReportsPage.model';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {BackgroundImage} from '../../common/utils/Media';
import ScrollToTop from '../../common/utils/ScrollToTop';
import ReportsList from '../../components/ReportsList/ReportsList';

import './ReportsPage.css';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


function ReportsPage() {
    const {
        data: reportsPage,
        loading,
        error
    } = useFetch<ReportsPageResponseDataItem>('/reports-page/?populate=*');

    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    if (loading) {
        return (<Loader/>);
    }

    if (error || !reportsPage) {
        return (<></>);
    }

    return (
        <div ref={ref}>
            <ScrollToTop/>

            <h1 className="page__main-title"
                style={{backgroundImage: BackgroundImage(reportsPage.attributes.image, isIntersecting, 'large', true)}}>
                <span className="page__main-title-text"><LocalizedText item={reportsPage} field="title"/></span>
            </h1>
            <div className="page__description">
                <span className="page__description-text"><LocalizedText item={reportsPage} field="description"/></span>
            </div>
            <ReportsList/>
        </div>
    );
}

export default ReportsPage;
