import React, {Suspense, useState} from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import {ToastContextProvider} from '../../contexts/toast';
import {LoaderType} from '../../components/Loader/Loader.mode';
import Contacts from '../../sections/Contacts/Contacts';
import {useLocation} from 'react-router-dom';

import './Root.css';
import {NavLinks} from '../../router';


interface RootRoute {
    outlet?: React.ReactElement;
}

function Root(props: RootRoute) {
    const [faderVisibility, toggleFader] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname.replace('/', '');
    const showOnlyOutlet = location.pathname === `/${NavLinks.fpvMonoDronesDonationPage}`;

    if (showOnlyOutlet) {
        return (<Outlet/>);
    }

    return (
        <ToastContextProvider>
            <Header toggleFader={toggleFader}/>
            <Suspense fallback={<Loader type={LoaderType.Page}/>}>
                <div className={`page ${Object.values(NavLinks).includes(currentPath) ? currentPath : ''}`}>
                    {props.outlet ? props.outlet : <Outlet/>}
                </div>
                <div className={`page__fader ${faderVisibility ? 'page__fader_visible' : ''}`}></div>
            </Suspense>
            <Contacts/>
        </ToastContextProvider>
    );
}

export default Root;
