import { useTranslation } from 'react-i18next';
import { ResponseDataItem } from '../common/models/Generic.model';


function useGetLocalizedField(item: ResponseDataItem<any>, field: string): string {
  const { i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;
  const data = item.attributes.localizations?.data;
  let localizedField;

  if (item.attributes.locale === currentLang) {
    localizedField = item.attributes[field];
  } else {
    localizedField = data && data.length ? data[0].attributes[field] : item.attributes[field];
  }

  return localizedField;
}

export default useGetLocalizedField;
