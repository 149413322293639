import React from 'react';
import {ReportsListResponseDataItem} from './ReportsList.model';
import useFetch from '../../hooks/useFetch';
import Loader from '../Loader/Loader';

import './ReportsList.css';
import ReportGroupItem from './ReporGrouptItem/ReportGroupItem';


function ReportsList() {
    const {
        data: reports,
        loading,
        error
        // Here is a way how to get file item for the nested component entry - https://docs.strapi.io/dev-docs/api/rest/populate-select#deeply-populate-a-2-level-component--media
    } = useFetch<ReportsListResponseDataItem[]>('/reports/?populate[0]=reports,localizations&populate[1]=localizations.reports.file.media&populate[2]=reports.file.media');

    if (loading) {
        return (<Loader/>);
    }

    if (error || !reports || !reports.length) {
        return (<></>);
    }

    return (
        <div className="reports-list">
            {reports
                .sort((a, b) => b.attributes.order - a.attributes.order)
                .map(report => (
                    <ReportGroupItem report={report} key={report.id}/>
                ))}
        </div>
    );
}

export default ReportsList;
