import React from 'react';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import {
    FundraisingListResponseDataItem,
    FundraisingListResponseDataItemStatus
} from './Fundraising.model';
import FundraisingItem from '../../components/FundraisingItem/FundraisingItem';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader/Loader';

import './Fundraising.css';
import useFetch from '../../hooks/useFetch';
import {NavLinks} from '../../router';


function Fundraising() {
    const {t} = useTranslation();
    const {
        data: fundraising,
        loading,
        error
    } = useFetch<FundraisingListResponseDataItem[]>('/fundraisings/?populate=*', NavLinks.fundraisings);

    if (loading) {
        return (<div><Loader/></div>);
    }

    if (error || !fundraising?.length) {
        return (<div></div>);
    }

    const renderSlides = () =>
        fundraising
            .filter(fund => fund.attributes.status === FundraisingListResponseDataItemStatus.Ongoing)
            .sort((a, b) => b.attributes.order - a.attributes.order)
            .map((fund) => (
                <FundraisingItem key={fund.id} item={fund}/>
            ));

    return (
        <div className="fundraising-section">
            <div className="section__anchor" id={NavLinks.fundraisings}></div>
            <h2 className="fundraising-section__title">
                <span className="fundraising-section__title-icon">&nbsp;</span>
                {t('fundraisingSection.title')}
            </h2>
            <SlickSlider slides={renderSlides}/>
        </div>
    );
}

export default Fundraising;
