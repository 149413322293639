import {useState} from 'react';

function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    const [item, setInnerValue] = useState<T>(() => {
        try {
            const storedItem = window.sessionStorage.getItem(key);
            return storedItem ? JSON.parse(storedItem) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: T) => {
        try {
            setInnerValue(value);
            window.sessionStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.log(e);
        }
    };

    return [item, setValue];
}

export default useSessionStorage;
