import React, {createRef, useEffect, useState} from 'react';
import Slider, {Settings} from 'react-slick';
import {useTranslation} from 'react-i18next';

import './SlickSlider.css';


export interface SlickSliderProps {
    slides: () => React.JSX.Element[]
}

interface ArrowBtnProps {
    className?: string;
    currentSlide?: number;
    'data-role'?: string;
    onClick?: () => any | null;
    slideCount?: number;
    style?: Record<string, any>;
}

function PrevArrow(props: ArrowBtnProps) {
    const {className, onClick} = props;
    const isDisabled = className?.includes('disabled');
    const {t} = useTranslation();

    return (
        <button type="button"
                aria-label={t('slider.prevBtnAriaLabel') || ''}
                disabled={isDisabled}
                className="slider__button"
                onClick={onClick}></button>
    );
}

function NextArrow(props: ArrowBtnProps) {
    const {className, onClick} = props;
    const isDisabled = className?.includes('disabled');
    const {t} = useTranslation();

    return (
        <button type="button"
                aria-label={t('slider.nextBtnAriaLabel') || ''}
                disabled={isDisabled}
                className="slider__button slider__button-next"
                onClick={onClick}></button>
    );
}

function SlickSlider(props: SlickSliderProps) {
    const slides = props.slides();
    const slidesLength = slides.length;
    const fundraisingItemWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--fundraising-item-width').replace('px', ''), 10);

    const sliderRef = createRef<Slider>();

    const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({width: 360, height: 0});

    useEffect(() => {
        const handleResize = () => setWindowSize({width: window.innerWidth, height: window.innerHeight});

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slidesToShow = Math.floor(windowSize.width / fundraisingItemWidth);

    const settings: Settings = {
        accessibility: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 300,
        variableWidth: true,
        slidesToScroll: 1,
        slidesToShow: slidesLength < slidesToShow ? slidesLength : slidesToShow,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>
    };

    return (
        <Slider ref={sliderRef} {...settings} className={slidesLength <= slidesToShow ? 'slick-slider_no-arrows' : ''}>
            {slides}
        </Slider>
    );
}

export default SlickSlider;
