import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import ProjectItem from '../../components/ProjectItem/ProjectItem';
import {ProjectListResponseDataItem} from './Projects.model';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';
import {NavLinks} from '../../router';

import './Projects.css';


function Projects() {
    const {t} = useTranslation();

    const {
        data: projects,
        loading,
        error
    } = useFetch<ProjectListResponseDataItem[]>('/projects/?populate=*', NavLinks.projects);

    if (loading) {
        return (<Loader/>);
    }

    if (error || !projects?.length) {
        return (<></>);
    }

    const renderSlides = () =>
        projects
            .sort((a, b) => b.attributes.order - a.attributes.order)
            .map((project, index) => (
                <ProjectItem key={project.id} item={project} index={index}/>
            ));

    return (
        <div className="projects-section">
            <div className="projects-section__top-block">
                <h2 className="projects-section__title projects-section__title_desktop">
                    <Trans
                        i18nKey="projectsSection.title"
                        values={{
                            youWord: t('projectsSection.youWord'),
                        }}
                        components={{
                            spanBlack: <span className="text-highlight text-highlight_black"/>,
                            br: <br/>
                        }}
                    />
                </h2>
                <h2 className="projects-section__title projects-section__title_mobile">
                    {t('projectsSection.titleMobile')}
                </h2>
                <p className="projects-section__text">{t('projectsSection.text')}</p>
            </div>

            <SlickSlider slides={renderSlides}/>
        </div>
    );
}

export default Projects;
