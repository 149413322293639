export enum ToastType {
    Success = 'success',
    Error = 'error'
}

export interface ToastItem {
    type: ToastType;
    message: string;
    title?: string;
    lifetime?: number;
}

export type ToastAdd = (toast: ToastItem) => void;
