import React from 'react';
import About from '../../sections/About/About';
import Fundraising from '../../sections/Fundraising/Fundraising';
import Partners from '../../sections/Partners/Partners';
import AccountDetails from '../../sections/AccountDetails/AccountDetails';
import Projects from '../../sections/Projects/Projects';
import Team from '../../sections/Team/Team';
import CompletedFundraising from '../../sections/CompletedFundraising/CompletedFundraising';

function MainPage() {
    return (
        <div>
            <About/>
            <Fundraising/>
            <Projects/>
            <AccountDetails/>
            <CompletedFundraising/>
            <Team/>
            <Partners/>
        </div>
    );
}

export default MainPage;
