import {DronesStatsResponse} from './DronesStats.model';

export const DronesStatsPercentageThreshold: number = 25;

export function getDronesStatsAmountTotal(stats: DronesStatsResponse[]): number {
    return +(stats ? Math.floor(stats[0].amount_total ?? 0) : 0);
}

export function getDronesStatsTransactionsCount(stats: DronesStatsResponse[]): number {
    return +(stats ? (stats[0].transactions_count ?? 0) : 0);
}

export function getDronesStatsNeedAmount(dronePrice: number, dronesCountGoal: number): number {
    return dronePrice * dronesCountGoal;
}

export function getDronesStatsProgress(dronePrice: number, dronesCountGoal: number, amountTotal: number): number {
    const amount = getDronesStatsNeedAmount(dronePrice, dronesCountGoal);

    if (!amount || !amountTotal) {
        return 0;
    }

    const progressVal = amountTotal * 100 / amount;

    return progressVal > 100 ? 100 : Number((progressVal).toFixed(2));
}

export function getDronesStatsDronesCount(dronePrice: number, amountTotal: number): number {
    return Math.floor(amountTotal / dronePrice);
}