import React from 'react';
import {DronesBannerResponseDataItem} from './DronesBanner.model';
import PartnersList from '../PartnersList/PartnersList';
import LocalizedText from '../LocalizedText/LocalizedText';
import {BackgroundImage, MediaUrl} from '../../common/utils/Media';
import useFetch from '../../hooks/useFetch';
import {DronesStatsResponse} from '../../sections/DronesStats/DronesStats.model';
import Loader from '../Loader/Loader';
import {getDronesStatsAmountTotal} from '../../sections/DronesStats/DronesStats.utils';
import {useTranslation} from 'react-i18next';
import useSessionStorage from '../../hooks/useSessionStorage';
import {Link, useNavigate} from 'react-router-dom';

import './DronesBanner.css';
import {DronesPageResponseDataItem} from '../../pages/DronesPage/DronesPage.model';

function DronesBanner(props: { banner: DronesBannerResponseDataItem }) {
    const {t, i18n} = useTranslation();

    const {
        data: dronesPage,
        loading: dronesPageLoading,
        error: dronesPageError
    } = useFetch<DronesPageResponseDataItem>('/drones-page/?populate[0]=purchaseReports');

    const {
        data: stats,
        loading: statsLoading,
        error: statsError
    } = useFetch<DronesStatsResponse[]>('/transactions/stats-summary');

    const [isBannerHidden, setBannerHiddenState] = useSessionStorage<boolean>('umbrella-drones-banner-hidden', false);

    const navigateTo = useNavigate();

    if (statsLoading || dronesPageLoading) {
        return (<Loader/>);
    }

    if (statsError || dronesPageError || !stats || !dronesPage) {
        return (<></>);
    }

    const closeBanner = () => {
        setBannerHiddenState(true);
    }

    // const dronePrice: number = dronesPage.attributes.dronePrice;
    const amountTotal: number = getDronesStatsAmountTotal(stats);
    // const purchaseReports = dronesPage?.attributes.purchaseReports;

    // const dronesSpentAmount: number = purchaseReports?.reduce((acc, report) => acc + report.spentAmount, 0) ?? 0;
    // const dronesCollectedCount: number = purchaseReports?.reduce((acc, report) => acc + report.itemsCount, 0) ?? 0;
    // const dronesDeliveredCount: number = purchaseReports?.reduce((acc, report) => {
    //     const now = new Date().valueOf();
    //     const deliveredDate = report.deliveredDate ? new Date(report.deliveredDate).valueOf() : null;
    //
    //     return acc + (deliveredDate && now >= deliveredDate ? report.itemsCount : 0);
    // }, 0) ?? 0;

    // const dronesCollectedCountLeftover: number = getDronesStatsDronesCount(dronePrice, amountTotal - dronesSpentAmount);

    return (
        <article className={`drones-banner ${!isBannerHidden ? 'drones-banner_visible' : ''}`}
                 style={{backgroundImage: props.banner.attributes.bgImage ? BackgroundImage(props.banner.attributes.bgImage, true, 'medium') : undefined}}>
            <button className="drones-banner__close-btn" onClick={closeBanner}>close</button>

            <div onClick={() => navigateTo(props.banner.attributes.bannerLink)} className="drones-banner__content">
                <div className="drones-banner__content-right">
                    <img src={MediaUrl(props.banner.attributes.image, 'small')}
                         className="drones-banner__img"
                         alt="banner"/>
                    <div className="drones-banner__partners">
                        <h4 className="drones-banner__title"><LocalizedText item={props.banner} field="title"/></h4>
                        <PartnersList partners={props.banner.attributes.partners} format="thumbnail" skipLink={true}/>
                    </div>
                </div>

                <div className="drones-banner__content-left">
                    <div className="drones-banner__progress-wrapper">
                        <div className="drones-banner__stat-block">
                            <div className="drones-banner__stat-block-title">
                                {t('dronesBanner.moneyCollected')}
                            </div>
                            <div className="drones-banner__stat-block-value-wrap">
                                <span className="drones-banner__stat-block-value">
                                    {amountTotal.toLocaleString(i18n.resolvedLanguage)}
                                </span>
                                <span className="drones-banner__currency text-highlight text-highlight_white">
                                    {t('common.currency.UAH')}
                                </span>
                            </div>
                        </div>
                        {/*<div className="drones-banner__stat-block">
                            <div className="drones-banner__stat-block-title">
                                {t('dronesPage.counter.collectedTitle')}
                            </div>
                            <div className="drones-banner__stat-block-value">
                                {dronesCollectedCount + dronesCollectedCountLeftover}
                            </div>
                        </div>*/}
                        {/*<div className="drones-banner__stat-block drones-banner__stat-block_delivered">
                            <div className="drones-banner__stat-block-title">
                                {t('dronesPage.counter.deliveredTitle')}
                            </div>
                            <div className="drones-banner__stat-block-value">
                                {dronesDeliveredCount}
                            </div>
                        </div> */}
                    </div>
                    <Link onClick={(e) => e.stopPropagation()} to={props.banner.attributes.buttonLink}
                          className="drones-banner__button">
                        <LocalizedText item={props.banner} field="buttonTitle"/>
                    </Link>
                </div>
            </div>
        </article>
    );
}

export default DronesBanner;
