import React from 'react';
import {MediaUrl} from '../../common/utils/Media';
import {AvailableMediaFormats, ListResponse, Partner, ResponseDataItem} from '../../common/models/Generic.model';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {Link} from 'react-router-dom';

import './PartnersList.css';

type PartnersListProps = {
    partners: ListResponse<ResponseDataItem<Partner>>;
    format?: AvailableMediaFormats;
    skipLink?: boolean;
}

const PartnersList: React.FC<PartnersListProps> = ({partners, format, skipLink = false}) => {
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    const getFormatClass = () => {
        switch (format) {
            case 'thumbnail': {
                return 'partners__list_thumbnail-size';
            }
            case 'medium': {
                return 'partners__list_medium-size';
            }
            default: {
                return '';
            }
        }
    }

    return (
        <div className={`partners__list ${getFormatClass()}`} ref={ref}>
            {partners.data
                .sort((a, b) => a.attributes.order - b.attributes.order)
                .map(partner => (
                    skipLink || !partner.attributes.link
                        ? <span key={partner.id} className="partners__item">
                            {isIntersecting &&
                                <img src={MediaUrl(partner.attributes.logo, format ?? 'thumbnail')}
                                     alt={partner.attributes.link}
                                     className="partners__item-logo"/>
                            }
                        </span>

                        : <Link key={partner.id} to={partner.attributes.link}
                             className="partners__item"
                             target="_blank"
                             rel="noopener noreferrer">
                            {isIntersecting &&
                                <img src={MediaUrl(partner.attributes.logo, format ?? 'thumbnail')}
                                     alt={partner.attributes.link}
                                     className="partners__item-logo"/>
                            }
                        </Link>

                ))}
        </div>
    );
};

export default PartnersList;
