import {ReactNode, useCallback, useState} from 'react';
import {ToastContext} from './ToastContext';
import {ToastItem} from './Toast.model';

import './Toast.css';


export function ToastContextProvider({children}: { children: ReactNode }) {
    const [toasts, setToasts] = useState<ToastItem[]>([]);

    const addToast = useCallback(
        function (toast: ToastItem) {
            setToasts((toasts: ToastItem[]) => [...toasts, toast]);
            setTimeout(() => setToasts((toasts) => toasts.slice(1)), 10000);
        },
        [setToasts]
    );

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <div className="toasts-wrapper">
                {toasts.map((toast, index) => (
                    <div className={`toast toast_${toast.type}`}
                         key={toast.message + index}>
                        {toast.title ? (<h5 className="toast__title">{toast.title}</h5>) : ''}
                        {toast.message}
                    </div>
                ))}
            </div>
        </ToastContext.Provider>
    );
}
