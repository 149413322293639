import React from 'react';
import {Report} from '../ReportsList.model';
import {MediaUrl} from '../../../common/utils/Media';

import './ReportItem.css';


function ReportItem(props: { item: Report }) {
    if (!props.item.link && !props.item.file) {
        return (<></>)
    }

    return (
        <a href={props.item.link ? props.item.link : MediaUrl(props.item.file!)}
           className="report-item"
           download="true"
           target="_blank"
           rel="noreferrer">
            {props.item.title}
        </a>
    )
}

export default ReportItem;
