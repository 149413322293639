import {createBrowserRouter} from 'react-router-dom';
import Root from './pages/Root/Root';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import MainPage from './pages/MainPage/MainPage';
import ReportsPage from './pages/ReportsPage/ReportsPage';
import AboutPage from './pages/AboutPage/AboutPage';
import PublicOfferPage from './pages/PublicOfferPage/PublicOfferPage';
import PartnerProjectFPVPage from './pages/PartnerProjectFPVPage/PartnerProjectFPVPage';
import DonationWidget from './pages/FPVDronesMonoDanationPage/DonationWidget';
import DronesPage from './pages/DronesPage/DronesPage';

export const NavLinks = {
    home: '/',
    about: 'about',
    accountDetails: 'account-details',
    projects: 'projects',
    fundraisings: 'fundraisings',
    reports: 'reports',
    partners: 'partners',
    contacts: 'contacts',
    reportsPage: 'reports-page',
    aboutPage: 'about-page',
    publicOfferPage: 'public-offer-page',
    dronesPage: 'drones-page',
    fpvMonoDronesDonationPage: 'fpv-drones-donation-widget',
    fpvDronesDonationPage: 'fpv-drones-donation-page',
    oldFpvDronesDonationPage: '1000-fpv-drones-donation-page',
}

export const router = createBrowserRouter([
    {
        element: <Root/>,
        errorElement: <Root outlet={<ErrorPage/>}/>,
        children: [
            {
                path: NavLinks.home,
                element: <MainPage/>,
            },
            {
                path: `/${NavLinks.reportsPage}`,
                element: <ReportsPage/>,
            },
            {
                path: `/${NavLinks.aboutPage}`,
                element: <AboutPage/>,
            },
            {
                path: `/${NavLinks.publicOfferPage}`,
                element: <PublicOfferPage />,
            },
            {
                path: `/${NavLinks.dronesPage}`,
                element: <DronesPage />,
            },
            {
                path: `/${NavLinks.fpvDronesDonationPage}`,
                element: <PartnerProjectFPVPage />,
            },
            {
                path: `/${NavLinks.oldFpvDronesDonationPage}`,
                element: <PartnerProjectFPVPage />,
            },
            {
                path: `/${NavLinks.fpvMonoDronesDonationPage}`,
                element: <DonationWidget />,
            }
        ],
    },
]);
