import React from 'react';
import useFetch from '../../hooks/useFetch';
import Loader from '../../components/Loader/Loader';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {BackgroundImage} from '../../common/utils/Media';
import ScrollToTop from '../../common/utils/ScrollToTop';
import {AboutPageResponseDataItem} from './AboutPage.model';
import Team from '../../sections/Team/Team';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import './AboutPage.css';


function AboutPage() {
    const {
        data: aboutPage,
        loading,
        error
    } = useFetch<AboutPageResponseDataItem>('/about-page/?populate=*');

    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    if (loading) {
        return (<Loader/>);
    }

    if (error || !aboutPage) {
        return (<></>);
    }

    return (
        <div ref={ref}>
            <ScrollToTop/>

            <h1 className="page__main-title"
                style={{backgroundImage: BackgroundImage(aboutPage.attributes.image, isIntersecting, 'large', true)}}>
                <span className="page__main-title-text"><LocalizedText item={aboutPage} field="title"/></span>
            </h1>
            <div className="page__description">
                <span className="page__description-text"><LocalizedText item={aboutPage} field="description"/></span>
            </div>

            {!!aboutPage.attributes.missionTitle &&
                <h2 className="about-page__mission-title">
                    <LocalizedText item={aboutPage} field="missionTitle"/>
                </h2>
            }

            {!!(aboutPage.attributes.missionLeft || aboutPage.attributes.missionRight) &&
                <div className="about-page__mission-text">
                    {aboutPage.attributes.missionLeft &&
                        <div className="about-page__mission-text-col">
                            <LocalizedText item={aboutPage} field="missionLeft"/>
                        </div>
                    }
                    {aboutPage.attributes.missionRight &&
                        <div className="about-page__mission-text-col">
                            <LocalizedText item={aboutPage} field="missionRight"/>
                        </div>
                    }
                </div>
            }

            <Team/>
        </div>
    );
}

export default AboutPage;
