import React from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

import './TranslateButton.css';


const supportedLanguages: Record<string, { nativeName: string }> = {
    uk: {nativeName: 'Укр'},
    en: {nativeName: 'En'}
};

function TranslateButton() {
    const {i18n} = useTranslation();

    return (
        <button type="button"
                className="translate-btn"
                onClick={() => i18n.changeLanguage(Object.keys(supportedLanguages).find(lng => lng !== i18next.resolvedLanguage))}>
            {supportedLanguages[i18next.resolvedLanguage!]?.nativeName}
        </button>

    );
}

export default TranslateButton;
