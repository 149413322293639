import React from 'react';
import {useTranslation} from 'react-i18next';
import {DefaultTFuncReturn} from 'i18next';
import {NavLinks} from '../../router';

import './HelpButton.css';
import {Link} from 'react-router-dom';


function HelpButton(props: { isBig?: boolean, link?: string, text?: DefaultTFuncReturn }) {
    const {t} = useTranslation();

    return (
        <Link to={props.link ?? `/#${NavLinks.accountDetails}`}
              className={`help-btn ${props.isBig ? 'help-btn_big' : ''}`}>
            {props.text ? props.text : t('helpBtn')}
        </Link>
    );
}

export default HelpButton;
