import React, {useState} from 'react';
import {ReportsListResponseDataItem} from '../ReportsList.model';
import LocalizedText from '../../LocalizedText/LocalizedText';
import {useTranslation} from 'react-i18next';
import ReportItem from '../ReportItem/ReportItem';

import './ReportGroupItem.css';


function ReportGroupItem(props: { report: ReportsListResponseDataItem }) {
    const [opened, changeOpenState] = useState<boolean>(false);
    const [reportItemsMaxHeight, setReportItemsMaxHeight] = useState<number>(0);

    const {i18n} = useTranslation();

    const currentLang = i18n.resolvedLanguage;

    const toggleContent = (id: number) => {
        changeOpenState(!opened);

        const reportItems = document.getElementById(id.toString());
        setReportItemsMaxHeight(reportItems?.scrollHeight && !opened ? reportItems.scrollHeight : 0);
    }

    return (
        <div className={`report ${opened ? 'report_opened' : ''}`}>
            <h5 className="report-title" onClick={() => toggleContent(props.report.id)}>
                <div className="report-title__text">
                    {<LocalizedText item={props.report} field="groupTitle"/>}
                </div>
            </h5>

            <div className="report-items" id={props.report.id.toString()}
                 style={{'maxHeight': reportItemsMaxHeight + 'px'}}>
                {
                    (props.report.attributes.locale !== currentLang && props.report.attributes.localizations?.data![0]?.attributes.reports?.length
                        ? props.report.attributes.localizations?.data![0]?.attributes.reports
                        : props.report.attributes.reports)
                        .sort((a, b) => b.order - a.order)
                        .map(reportItem => (
                            <ReportItem item={reportItem} key={reportItem.id}/>
                        ))
                }
            </div>
        </div>
    )
}

export default ReportGroupItem;
