import {Umbrella_Public_Offer} from './Umbrella_Public_Offer'


function PublicOfferPage() {
    return (
        <div dangerouslySetInnerHTML={{ __html: Umbrella_Public_Offer }} />
    );
}

export default PublicOfferPage;
