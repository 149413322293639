import {
    Currency,
    GenericMediaType,
    ResponseDataItem,
    ResponseDataItemAttributes
} from '../../common/models/Generic.model';

export enum FundraisingListResponseDataItemStatus {
    Ongoing = "ongoing",
    Completed = "completed",
}

export interface FundraisingListResponseDataItemAttributes extends ResponseDataItemAttributes<FundraisingListResponseDataItem> {
    name: string;
    status: FundraisingListResponseDataItemStatus;
    needAmount: number;
    collectedAmount: number;
    collectedAmountApiUrl?: string;
    currency: Currency;
    order: number;
    photo: GenericMediaType;
    description: string;
    link?: string;
}

export type FundraisingListResponseDataItem = ResponseDataItem<FundraisingListResponseDataItemAttributes>;
