import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LiqPayWidget from '../../components/LiqPayWidget/LiqPayWidget';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import useFetch from '../../hooks/useFetch';
import Loader from '../../components/Loader/Loader';
import PartnersList from '../../components/PartnersList/PartnersList';
import { PartnerResponseDataItem } from './PartnerProjectFPVPage.model';
import DonationWidget from '../FPVDronesMonoDanationPage/DonationWidget';
import { Tab, Tabs, Box } from '@mui/material';

import './PartnerProjectFPVPage.css';

const isMobileDevice = () => {
    return window.matchMedia("(max-width: 768px)").matches;
};

function PartnerProjectFPVPage() {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(isMobileDevice() ? 1 : 0);
    const {
        data: donationPageData,
        loading,
        error
    } = useFetch<PartnerResponseDataItem>('/liq-pay-widget/?populate[0]=localizations,partners&populate[1]=partners.localizations,partners.logo');

    if (loading) {
        return (<Loader />);
    }

    if (error || !donationPageData) {
        return (<></>);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <div>
            <h2 className="about-section__block-title project-fpv-page__title">
                <LocalizedText item={donationPageData} field="title" />
            </h2>
            <p className="about-section__block-text about-section__block-text__mission">
                <LocalizedText item={donationPageData} field="description" />
            </p>

            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                >
                    <Tab label={t('widget.liqpay.title')} />
                    <Tab label={t('widget.monobank.title')} />
                </Tabs>
            </Box>

            <Box sx={{
                    padding: '32px 16px',
                    background: '#eff1f3',
                    display: 'flex',
                    justifyContent: 'center',
            }}>
                {selectedTab === 0 && <LiqPayWidget item={donationPageData} />}
                {selectedTab === 1 && <DonationWidget />}
            </Box>

            <div className="project-fpv-page__partners">
                <PartnersList partners={donationPageData.attributes.partners} />
            </div>
        </div>
    );
}

export default PartnerProjectFPVPage;
