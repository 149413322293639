import {useEffect, useState} from 'react';
import {ErrorResponseDataItem} from '../common/models/Generic.model';
import {ToastType, useToastContext} from '../contexts/toast';
import {useLocation} from 'react-router-dom';

const cache = new Map<string, any>(null);

function useFetch<T>(url: string | null, sectionToScroll?: string): {
    data: T | null,
    loading: boolean,
    error: ErrorResponseDataItem | null
} {
    const addToast = useToastContext();
    const location = useLocation();

    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorResponseDataItem | null>(null);

    useEffect(() => {
        const scrollToAnchor = (sectionName: string) => {
            setTimeout(() => {
                if (location.hash.replace('#', '') === sectionName) {
                    const sectionEl = document.getElementById(sectionName);

                    if (!sectionEl) {
                        return;
                    }

                    sectionEl.scrollIntoView();
                }
            });
        }

        const showError = (message: string, title?: string) => {
            addToast({type: ToastType.Error, message, title});
        }

        // const controller = new AbortController();

        setLoading(true);
        setData(null);
        setError(null);

        if (!url) {
            setLoading(false);
            return;
        }

        const cachedData = cache.get(url);

        if (cachedData) {
            setLoading(false);
            setData(cachedData);

            if (sectionToScroll) {
                scrollToAnchor(sectionToScroll);
            }
        } else {

            fetch(`${process.env.REACT_APP_API_URL}${url}`/*, {signal: controller.signal}*/)
                .then(result => result.json())
                .then(result => {
                    if (!result.data && result.error) {
                        setError(result.error);
                        showError(result.error.message, `${sectionToScroll}: `);
                    } else {
                        setData(result.data);
                        cache.set(url, result.data);
                    }
                })
                .catch(error => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);

                    if (sectionToScroll) {
                        scrollToAnchor(sectionToScroll);
                    }
                })
            /*return () => {
                controller.abort();
            }*/
        }
    }, [url, sectionToScroll, addToast, location.hash])


    return {data, loading, error}
}

export default useFetch;
