import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import Loader from '../../components/Loader/Loader';
import {AboutResponseDataItem} from './About.model';
import useFetch from '../../hooks/useFetch';
import {NavLinks} from '../../router';
import {BackgroundImage} from '../../common/utils/Media';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import './About.css';


function About() {
    const {t} = useTranslation();
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    const {data: about, loading, error} = useFetch<AboutResponseDataItem>('/about/?populate=*', NavLinks.about);

    if (loading) {
        return (<Loader/>);
    }

    if (error || !about) {
        return (<></>);
    }

    return (
        <>
            <h1 className="about-section__main-title" ref={ref}
                style={{backgroundImage: BackgroundImage(about.attributes.image, isIntersecting, 'large', true)}}>
                <span>
                    <LocalizedText item={about} field="title"/>
                </span>
            </h1>
            <article className="about-section">
                <div className="section__anchor" id={NavLinks.about}></div>
                <section className="about-section__block about-section__block-mission">
                    <h2 className="about-section__block-title">
                        <LocalizedText item={about} field="missionTitle"/>
                    </h2>
                    <p className="about-section__block-text about-section__block-text__mission">
                        <LocalizedText item={about} field="missionDescription"/>
                    </p>
                </section>
                <section className="about-section__block about-section__block-fund">
                    <h2 className="about-section__block-title">
                        <LocalizedText item={about} field="fundTitle"/>
                    </h2>
                    <p className="about-section__block-text">
                        <LocalizedText item={about} field="fundDescription" html={true}/>
                    </p>
                    <NavLink to={`/${NavLinks.aboutPage}`}
                             className="about-section__details-link">
                        {t('aboutSection.detailsLink')}
                    </NavLink>
                </section>
            </article>
        </>
    );
}

export default About;
