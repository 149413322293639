import React from 'react';
import {GenericSocialMediaComponent, GenericSocialMediaType} from '../../common/models/Generic.model';
import {Link} from 'react-router-dom';

import './SocialLink.css';


const getSocialMediaClass = (type: GenericSocialMediaType) => {
    switch (type) {
        case 'facebook':
            return 'social-links_facebook';
        case 'instagram':
            return 'social-links_instagram';
        case 'linkedIn':
            return 'social-links_linkedin';
        case 'linktree':
            return 'social-links_linktree';
        case 'tiktok':
            return 'social-links_tiktok';
        default:
            return '';
    }
}

function SocialLink(props: {socialMedia: GenericSocialMediaComponent}) {
    return (
        <Link className={`social-link ${getSocialMediaClass(props.socialMedia.type)}`}
           to={props.socialMedia.link}
           rel="noreferrer"
           target="_blank">{props.socialMedia.link}</Link>
    );
}

export default SocialLink;
