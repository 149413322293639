import React from 'react';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';
import {DronesPageResponseDataItem, PurchaseReport} from '../../pages/DronesPage/DronesPage.model';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {NavLinks} from '../../router';
import {DronesStatsResponse} from './DronesStats.model';
import {getDronesStatsAmountTotal} from './DronesStats.utils';
import {Link} from 'react-router-dom';
import {MediaUrl} from '../../common/utils/Media';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import './DronesStats.css';

type DronesStatsProps = {
    dronePageData: DronesPageResponseDataItem;
}

function DronesStats(props: DronesStatsProps) {
    const {t, i18n} = useTranslation();
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});
    const currentLang = i18n.resolvedLanguage;

    const {
        data: stats,
        loading,
        error
    } = useFetch<DronesStatsResponse[]>('/transactions/stats-summary');

    const today = new Date().toLocaleDateString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    if (loading) {
        return (<Loader/>);
    }

    if (error || !stats) {
        return (<></>);
    }

    const areDronesDelivered: (deliveredDate: string | undefined) => boolean = (deliveredDate) => {
        if (!deliveredDate) {
            return false;
        }

        const now = new Date().valueOf();
        const deliveredDateTimestamp = new Date(deliveredDate).valueOf();

        return now >= deliveredDateTimestamp;
    }

    // const dronePrice: number = props.dronePageData.attributes.dronePrice;
    const purchaseReports: PurchaseReport[] = props.dronePageData.attributes.purchaseReports
        .sort((a, b) => Number(a.status === 'reserved') - Number(b.status === 'reserved'));

    const purchaseReportsLocalization: PurchaseReport[] | null = props.dronePageData.attributes.localizations?.data
        ? props.dronePageData.attributes.localizations?.data[0].attributes.purchaseReports
            .sort((a, b) => Number(a.status === 'reserved') - Number(b.status === 'reserved'))
        : null;

    const amountTotal: number = getDronesStatsAmountTotal(stats);

    // const dronesCollectedAmount: number = purchaseReports
    //     .reduce((acc, report) => acc + report.spentAmount, 0);

    const dronesDeliveredAmount: number = purchaseReports
        .filter(report => report.status === 'delivered')
        .reduce((acc, report) => acc + report.spentAmount, 0);

    const dronesReservedAmount: number = purchaseReports
        .filter(report => report.status === 'reserved')
        .reduce((acc, report) => acc + report.spentAmount, 0);

    // const dronesCollectedCount: number = purchaseReports.reduce((acc, report) => acc + report.itemsCount, 0);
    const dronesReservedCount: number = purchaseReports
        .filter(report => report.status === 'reserved')
        .reduce((acc, report) => acc + report.itemsCount, 0);
    const dronesDeliveredCount: number = purchaseReports
        .reduce((acc, report) => acc + (areDronesDelivered(report.deliveredDate) ? report.itemsCount : 0), 0);

    // const dronesCollectedCountLeftover: number = getDronesStatsDronesCount(dronePrice, amountTotal - dronesCollectedAmount);

    const getItemType: (itemType: string | undefined, i: number) => string = (itemType: string | undefined, i: number) => {
        if (!itemType) {
            return '';
        }

        return purchaseReportsLocalization && currentLang !== props.dronePageData.attributes.locale ? (purchaseReportsLocalization[i]?.itemType || '') : itemType;
    }

    const getMilitaryBaseName: (baseName: string | undefined, i: number) => string = (baseName: string | undefined, i: number) => {
        if (!baseName) {
            return '';
        }

        return purchaseReportsLocalization && currentLang !== props.dronePageData.attributes.locale ? (purchaseReportsLocalization[i]?.militaryBaseName || '') : baseName;
    }

    return (
        <div className="drones-stats">
            <h2 className="drones-stats__title">
                <span><LocalizedText item={props.dronePageData} field="statsTitle"/></span>
                <span className="drones-stats__title-date">
                    {t('dronesPage.stats.date')} {today}
                </span>
            </h2>
            <article className="drones-stats__container">
                <div className="drones-stats__need-amount-wrapper">
                    <div className="drones-stats__need-amount">
                        <div className="drones-counter__content-values-title">
                            {t('dronesPage.stats.title')}
                        </div>
                        <div className="drones-stats__need-amount-value-wrap">
                            <span className="drones-stats__need-amount-value">
                                {amountTotal.toLocaleString(i18n.resolvedLanguage)}
                            </span>
                            <span className="drones-stats__currency text-highlight text-highlight_yellow">
                                {t('common.currency.UAH')}
                            </span>
                        </div>
                        <Link to={`/${NavLinks.fpvDronesDonationPage}`}
                              className="fundraising-item__button drones-stats__progress-button">
                            {t('dronesPage.stats.button')}
                        </Link>
                    </div>
                </div>
                <div className="drones-counter__content-values-wrapper">
                    {/* <div className="drones-counter__content-values">
                        <div className="drones-counter__content-values-title">
                            {t('dronesPage.counter.collectedTitle')}
                        </div>
                        <div className="drones-counter__content-values-number">
                            {dronesCollectedCount + dronesCollectedCountLeftover}
                        </div>
                    </div>*/}
                    {/*<div className="drones-counter__content-values">
                        <div className="drones-counter__content-values-title">
                            {t('dronesPage.counter.deliveredTitle')}
                        </div>
                        <div
                            className="drones-counter__content-values-number drones-counter__content-values-number_leftover">
                            {dronesDeliveredCount}
                        </div>
                    </div> */}
                </div>
            </article>

            <article className="drones-stats__table-block">
                <h5 className="drones-stats__table-title">{t('dronesPage.table.title')}</h5>
                <table ref={ref} className="drones-stats__table">
                    <thead>
                    <tr>
                        <th className="drones-stats__table-img-header"></th>
                        <th>{t('dronesPage.table.header.baseName')}</th>
                        <th className="drones-stats__table-count-header">
                            {t('dronesPage.table.header.amount')}
                            <span
                                className="drones-stats__table-date-header_merged"> / {t('dronesPage.table.header.date')}</span>
                        </th>
                        <th className="drones-stats__table-date-header">{t('dronesPage.table.header.date')}</th>
                        <th>{t('dronesPage.table.header.status')}
                            <span
                                className="drones-stats__table-date-header_merged"> / {t('dronesPage.table.header.sum')}</span>
                        </th>
                        <th className="drones-stats__table-date-header">{t('dronesPage.table.header.sum')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {purchaseReports?.length && purchaseReports
                        .map((report, i) => {
                            const deliveredDate = report.deliveredDate && areDronesDelivered(report.deliveredDate)
                                ? new Date(report.deliveredDate).toLocaleDateString(i18n.resolvedLanguage, {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                })
                                : t('dronesPage.table.dataStatus.ongoing');

                            return (
                                <tr key={report.id} className={'drones-stats__row_' + report.status}>
                                    <td className="drones-stats__table-img">
                                        {isIntersecting && report.militaryBaseChevron &&
                                            <img src={MediaUrl(report.militaryBaseChevron, 'thumbnail')}
                                                 alt={report.militaryBaseName}/>
                                        }
                                    </td>
                                    <td>{getMilitaryBaseName(report.militaryBaseName, i)}</td>
                                    <td className="drones-stats__table-count">
                                        <div className="drones-stats__table-merged-item">
                                            <div className="drones-stats__table-merged-title">
                                                {t('dronesPage.table.header.amount')}:
                                            </div>
                                            <div>{report.itemsCount} {getItemType(report.itemType, i)}</div>
                                        </div>
                                        <div className="drones-stats__table-date_merged">
                                            <div className="drones-stats__table-merged-title">
                                                {t('dronesPage.table.header.date')}:
                                            </div>
                                            <div>{deliveredDate}</div>
                                        </div>
                                    </td>
                                    <td className="drones-stats__table-date">{deliveredDate}</td>
                                    <td>
                                        <div className="drones-stats__table-merged-item">
                                            <div className="drones-stats__table-merged-title">
                                                {t('dronesPage.table.header.status')}:
                                            </div>
                                            <div>{t('dronesPage.table.status.' + report.status)}</div>
                                        </div>
                                        <div className="drones-stats__table-date_merged">
                                            <div className="drones-stats__table-merged-title">
                                                {t('dronesPage.table.header.sum')}:
                                            </div>
                                            <div>{report.spentAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}</div>
                                        </div>
                                    </td>
                                    <td className="drones-stats__table-date">{report.spentAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot className="drones-stats__table-footer">
                    <tr>
                        {/*className="drones-stats__table-footer-cell"*/}
                        <td className="drones-stats__table-img-footer"></td>
                        <td>{t('dronesPage.table.total')}</td>
                        <td>{dronesDeliveredCount}</td>
                        <td className="drones-stats__table-date"></td>
                        <td>
                            <div>{t('dronesPage.table.status.delivered')}</div>

                            <div className="drones-stats__table-date_merged">
                                {dronesDeliveredAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}
                            </div>
                        </td>
                        <td className="drones-stats__table-footer-cell">{dronesDeliveredAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}</td>
                    </tr>
                    <tr className={'drones-stats__row_reserved'}>
                        <td className="drones-stats__table-img-footer"></td>
                        <td></td>
                        <td>{dronesReservedCount}</td>
                        <td className="drones-stats__table-date"></td>
                        <td>
                            <div>{t('dronesPage.table.status.reserved')}</div>

                            <div className="drones-stats__table-date_merged">
                                {dronesReservedAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}
                            </div>
                        </td>
                        <td className="drones-stats__table-footer-cell">{dronesReservedAmount.toLocaleString(i18n.resolvedLanguage)} {t('common.currency.UAH')}</td>
                    </tr>
                    </tfoot>
                </table>
            </article>
        </div>
    );
}

export default DronesStats;
