import React from 'react';
import {ProjectListResponseDataItem} from '../../sections/Projects/Projects.model';
import LocalizedText from '../LocalizedText/LocalizedText';

import './ProjectItem.css';


function ProjectItem(props: { item: ProjectListResponseDataItem, index: number }) {
    return (
        <article className="project-item" style={{backgroundColor: props.item.attributes.color}}>
            <div>
                <div className="project-item__order">
                    {props.item.attributes.order < 10 ? `0${props.index + 1}` : props.index}
                </div>
            </div>
            <header>
                <h3 className="project-item__title">
                    <LocalizedText item={props.item} field="name"/>
                </h3>
            </header>
            <p className="project-item__description">
                <LocalizedText item={props.item} field="description"/>
            </p>
        </article>
    );
}

export default ProjectItem;
