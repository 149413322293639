import React from 'react';
import Navigation from '../Navigation/Navigation';
import {HeaderProps} from './Header.model';
import {Link, useLocation} from 'react-router-dom';
import {NavLinks} from '../../router';
import DronesBanner from '../DronesBanner/DronesBanner';
import useFetch from '../../hooks/useFetch';
import {DronesBannerResponseDataItem} from '../DronesBanner/DronesBanner.model';

import './Header.css';

function Header(props: HeaderProps) {
    const {
        data: bannerData,
        loading,
        error
    } = useFetch<DronesBannerResponseDataItem>('/drones-banner/?populate[0]=image,bgImage,partners,localizations&populate[1]=partners.logo');

    const location = useLocation();

    return (
        <>
            {!loading && !error && bannerData?.attributes.enabled && !bannerData.attributes.exclude?.urls?.includes(location.pathname) &&
                <DronesBanner banner={bannerData}/>
            }
            <header className="header" id="header">
                <Link to={NavLinks.home} className="logo">Umbrella UA</Link>
                <Navigation toggleFader={props.toggleFader}/>
            </header>
        </>
    );
}

export default Header;
