import React, {useCallback, useEffect} from 'react';
import useGetLocalizedField from '../../hooks/useGetLocalizedField';
import {useTranslation} from 'react-i18next';
import {PartnerResponseDataItem} from '../../pages/PartnerProjectFPVPage/PartnerProjectFPVPage.model';

import './LiqPayWidget.css';

interface LiqPayWidgetProps {
    item: PartnerResponseDataItem;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Window {
    LiqPayCheckoutCallback: () => void;
    LiqPayCheckout: any;
}

const LiqPayWidget: React.FC<LiqPayWidgetProps> = ({item}) => {
    const {i18n} = useTranslation();

    const localizedData = useGetLocalizedField(item, 'widget_data');
    const localizedSignature = useGetLocalizedField(item, 'widget_signature');

    const handleLanguageChanged = useCallback(() => {
        (window as any).LiqPayCheckoutCallback();
    }, []);

    useEffect(() => {
        (window as any).LiqPayCheckoutCallback = function () {
            const checkoutContainer = document.getElementById('liqpay_checkout');
            const iframes = checkoutContainer?.querySelectorAll('iframe');
            iframes?.forEach(iframe => iframe.parentNode?.removeChild(iframe));

            (window as any).LiqPayCheckout.init({
                data: localizedData,
                signature: localizedSignature,
                embedTo: "#liqpay_checkout",
                mode: "embed",
                language: i18n.resolvedLanguage
            });
        };

        const script = document.createElement('script');
        script.src = "//static.liqpay.ua/libjs/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            delete (window as any).LiqPayCheckoutCallback;
        };
    }, [localizedData, localizedSignature, i18n]);

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged, i18n]);

    return <div className="liqpay-checkout" id="liqpay_checkout"/>;
};

export default LiqPayWidget;
