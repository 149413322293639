import React from 'react';
import {LoaderType} from './Loader.mode';

import './Loader.css';


function Loader(props: { type?: LoaderType }) {
    const defaultProps = {
        type: LoaderType.Section
    }

    const {type} = {...defaultProps, ...props};

    return (
        <div className={`loader loader_${type}`}>&nbsp;</div>
    );
}

export default Loader;
