import React, { useState } from 'react';
import './donation-widget.css';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const DonationWidget: React.FC = () => {
  const {t} = useTranslation();
  const [customAmount, setCustomAmount] = useState<string>('200');
  const [donateButtonDisabled, setDonateButtonDisabled] = useState<boolean>(false);
  const [donateButtonText, setDonateButtonText] = useState<string>('donate');
  const [searchParams] = useSearchParams();

  const handleOptionClick = (amount: string): void => {
    setCustomAmount(amount);
  };

  const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomAmount(event.target.value.replace(/[^0-9.,]/g, '').replace(',', '.').replace(/\.+/g, '.'));
  };

  const handleBlur = (): void => {
    let inputAmount = customAmount;

    const parts = inputAmount.split('.');
    if (parts.length > 2) {
      inputAmount = parts.join('');
    } else if (parts.length === 2) {
      parts[1] = parts[1].substring(0, 2);
      inputAmount = parts.join('.');
    }

    if (inputAmount === '' || parseFloat(inputAmount) === 0) {
      inputAmount = '200';
    }

    if (/^0+/.test(inputAmount)) {
      inputAmount = inputAmount.replace(/^0+/, '');
    }

    if (/^\./.test(inputAmount)) {
      inputAmount = '0' + inputAmount;
    }

    setCustomAmount(inputAmount);
  };

  const handleDonateButtonClick = async (): Promise<void> => {
    let amount: number = parseFloat(customAmount.replace(/[^0-9,.]/g, '').replace(',', '.'));
    amount = Math.round(amount * 100);
    if (isNaN(amount) || amount <= 0) {
      alert(t('widget.monobank.wrongSum'));
      return;
    }

    setDonateButtonDisabled(true);
    setDonateButtonText('loading');

    try {
      const url = `https://umbrellaua.charity/api/transactions/generate-mono-link?amount=${amount}&destination=${searchParams.get('destination') || 'A_CENTAURI'}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { pageUrl } = await response.json();

      window.parent.location.href = pageUrl;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      alert(t('widget.monobank.linkError'));
    } finally {
      setDonateButtonDisabled(false);
      setDonateButtonText('donate');
    }
  };

  return (
    <div className={`donation-widget ${searchParams.get('theme') === 'dark' ? 'dark' : 'light'}`}>
      <div className="donation-options">
        <button className="donate-option-button" onClick={() => handleOptionClick('100')}>100₴</button>
        <button className="donate-option-button" onClick={() => handleOptionClick('200')}>200₴</button>
        <button className="donate-option-button" onClick={() => handleOptionClick('500')}>500₴</button>
        <button className="donate-option-button" onClick={() => handleOptionClick('1000')}>1000₴</button>
      </div>
      <p className='input-label'>{t('widget.monobank.yourSum')}</p>
      <div className="custom-amount-container">
        <input type="text" className="custom-amount" value={customAmount} onChange={handleCustomInputChange} onBlur={handleBlur} />
        <span className='symbol'>₴</span>
      </div>
      <button className="donate-button" onClick={handleDonateButtonClick} disabled={donateButtonDisabled}>{t(`widget.monobank.${donateButtonText}`)}</button>
    </div>
  );
};

export default DonationWidget;
