import React from 'react';
import {PartnersListResponseDataItem} from './Partners.model';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';
import {NavLinks} from '../../router';
import PartnersList from '../../components/PartnersList/PartnersList';
import LocalizedText from '../../components/LocalizedText/LocalizedText';

import './Partners.css';


function Partners() {

    const {
        data: partners,
        loading,
        error
    } = useFetch<PartnersListResponseDataItem>('/partners-section/?populate[0]=localizations,partners&populate[1]=partners.localizations,partners.logo', NavLinks.partners);

    if (loading) {
        return (<Loader/>);
    }

    if (error || !partners?.attributes?.partners) {
        return (<></>);
    }

    return (
        <div className="partners-section">
            <div className="section__anchor" id={NavLinks.partners}></div>
            <h2 className="partners-section__title"><LocalizedText item={partners} field="title" /></h2>
            <div className="partners-section__block">
                <div className="partners-section__list">
                    <PartnersList partners={partners.attributes.partners} format='medium'></PartnersList>
                </div>
            </div>
        </div>
    );
}

export default Partners;
