import React from 'react';
import {useTranslation} from 'react-i18next';
import {TeamMemberListResponseDataItem} from './Team.model';
import {BackgroundImage} from '../../common/utils/Media';
import SocialLink from '../../components/SocialLink/SocialLink';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';

import './Team.css';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


function Team() {
    const {t} = useTranslation();
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    const {
        data: teamMembers,
        loading,
        error
    } = useFetch<TeamMemberListResponseDataItem[]>('/team-members/?populate=*', 'team');

    if (loading) {
        return (<Loader/>);
    }

    if (error || !teamMembers?.length) {
        return (<></>);
    }

    return (
        <div className="team-section" ref={ref}>
            <h2 className="team-section__title">{t('teamSection.title')}</h2>
            <div className="team-section__list">
                {teamMembers
                    .sort((a, b) => b.attributes.order - a.attributes.order)
                    .map(member => (
                        <div className="team-section__item" key={member.id}>
                            <div className="team-section__item-image"
                                 style={{backgroundImage: BackgroundImage(member.attributes.photo, isIntersecting)}}></div>
                            <h3 className="team-section__item-title">
                                <LocalizedText item={member} field="name"/>
                            </h3>
                            <div className="team-section__item-roles">
                                {member.attributes.roles.map((role, index) => (
                                    <span key={index}>
                                        {t(`teamSection.roles.${member.attributes.sex}.${role}`)}{index !== member.attributes.roles.length - 1 ? ', ' : ''}
                                    </span>
                                ))}
                                {member.attributes.customRole &&
                                    <div className="team-section__item-roles">
                                        <LocalizedText item={member} field="customRole"/>
                                    </div>
                                }
                            </div>
                            <div className="team-section__item-social-links">
                                {member.attributes.socialMedias.map(socialMedia => (
                                    <SocialLink key={socialMedia.id} socialMedia={socialMedia}/>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default Team;
