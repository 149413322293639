import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    FundraisingListResponseDataItem,
    FundraisingListResponseDataItemStatus
} from '../../sections/Fundraising/Fundraising.model';
import LocalizedText from '../LocalizedText/LocalizedText';
import {BackgroundImage} from '../../common/utils/Media';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {NavLinks} from '../../router';
import {Link} from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import {DronesStatsResponse} from '../../sections/DronesStats/DronesStats.model';
import Loader from '../Loader/Loader';
import {getDronesStatsAmountTotal} from '../../sections/DronesStats/DronesStats.utils';

import './FundraisingItem.css';

function FundraisingItem(props: { item: FundraisingListResponseDataItem }) {
    const {t, i18n} = useTranslation();
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    const {
        data: stats,
        loading,
        error
    } = useFetch<DronesStatsResponse[]>(props.item.attributes.collectedAmountApiUrl ?? null);

    if (loading) {
        return (<div><Loader/></div>);
    }

    if (error) {
        return (<div></div>);
    }

    const amountTotal: string = stats
        ? getDronesStatsAmountTotal(stats).toLocaleString(i18n.resolvedLanguage)
        : (+props.item.attributes.collectedAmount).toLocaleString(i18n.resolvedLanguage);

    const getProgress = (): number => {
        const needAmount = +props.item.attributes.needAmount;
        const collectedAmount = +props.item.attributes.collectedAmount;

        if (!needAmount || !collectedAmount) {
            return 0;
        }

        return collectedAmount * 100 / needAmount;
    }

    const needAmount = (+props.item.attributes.needAmount).toLocaleString(i18n.resolvedLanguage);

    const isOngoing = props.item.attributes.status === FundraisingListResponseDataItemStatus.Ongoing

    return (
        <article className="fundraising-item" ref={ref}>
            <div className="fundraising-item__image"
                 style={{backgroundImage: BackgroundImage(props.item.attributes.photo, isIntersecting)}}></div>
            <div className="fundraising-item__content">
                <header>
                    <h3 className="fundraising-item__content-title" lang={i18n.resolvedLanguage}>
                        <LocalizedText item={props.item} field="name"/>
                    </h3>
                </header>
                <p className="fundraising-item__content-description">
                    <LocalizedText item={props.item} field="description"/>
                </p>
                <div className="fundraising-item__bottom">
                    <div
                        className="fundraising-item__need-amount">{needAmount}
                        <span className="fundraising-item__currency text-highlight text-highlight_yellow">
                            {t(`common.currency.${props.item.attributes.currency}`)}
                        </span>
                    </div>
                    <div
                        className={`fundraising-item__progress ${isOngoing ? 'fundraising-item__progress_ongoing' : ''}`}>
                        <div className="fundraising-item__progress-bar" style={{width: `${getProgress()}%`}}></div>
                    </div>
                    {
                        isOngoing
                            ?
                            <div className="fundraising-item__collected-amount">
                                {t('fundraisingSection.collectedAmount.title')} {amountTotal} {t(`common.currency.${props.item.attributes.currency}`)}
                            </div>
                            : ''
                    }
                    {
                        isOngoing
                            ? <Link to={props.item.attributes.link ? props.item.attributes.link : `/#${NavLinks.accountDetails}`}
                                    className="fundraising-item__button">
                                {t('fundraisingSection.button')}
                            </Link>
                            : <div className="fundraising-item__button_complete">
                                <div className="complete__button"></div>
                            </div>
                    }
                </div>
            </div>
        </article>
    );
}

export default FundraisingItem;
